<template>
  <header>
    <div style="max-width: 95%; margin:auto; margin-top: 1%">

      <div :class="[isMobile ? 'mobile-header' : 'desktop-header']">

        <div v-if="isMobile" class="mobile-logo-container" style="padding-top: 5%;">

          <router-link v-if="step != 0" :to="path">

            <v-icon
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: #002c77; padding-bottom: 5%;">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <!-- <router-link v-if="step != 0" :to="path"> -->

          <div
            v-if="(stepQuestions == 1 || stepQuestions == 2 || stepQuestions == 3 || stepQuestions == 4) && step != 0">
            <v-icon
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: #002c77; padding-bottom: 5%;">
              mdi-chevron-left
            </v-icon>
          </div>

          <!-- </router-link> -->

          <!-- <router-link v-else :to="path">

            <v-icon @click="sitio()"
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: #002c77;">
              mdi-chevron-left
            </v-icon>

          </router-link> -->

          <!-- <router-link :to="{ path: '/' }"> -->
          <img :src="logo" alt="logo" width="130px" height="22px" class="mobile-logo">
          <!-- </router-link> -->

        </div>

        <div v-else class="desktop-logo-container" style=" padding-left: 3%;">

          <!-- <router-link :to="{ path: '/' }"> -->

          <img :src="logoNav" alt="Image 1" class="menu-nav"
            style="padding-top: 15px; float: right; padding-right: 39px; max-height: 50px; max-width: 50%;">

          <!-- </router-link> -->

          <div>

            <router-link :to="path" v-if="stepQuestions != 1 && stepQuestions != 2 && stepQuestions != 3 && stepQuestions != 4">

              <div v-if="step != 0">

                <v-icon style=" width: 20px; height: 20px; color: #002c77; font-size: 40px; padding-top: 29.5px;">
                  mdi-chevron-left
                </v-icon>

                <span class="textoH3" style="margin-left: 10px;">Volver atrás</span>

              </div>

            </router-link>

            <!-- <router-link to="/dps" v-else @click.prevent="returnQuestions"> -->

            <div
              v-if="(stepQuestions == 1 || stepQuestions == 2 || stepQuestions == 3 || stepQuestions == 4) && step != 0">

              <v-icon style=" width: 20px; height: 20px; color: #002c77; font-size: 40px; padding-top: 29.5px;">
                mdi-chevron-left
              </v-icon>

              <button @click="returnQuestions"><span class="textoH3" style="margin-left: 5px; margin-top: -18px;">Volver
                  atrás</span></button>

            </div>

            <!-- </router-link> -->

          </div>

        </div>

      </div>

    </div>

    <br>

  </header>
</template>
<script>
import logo from '@/assets/logo2.png';
import MenuNav from '@/assets/MenuNav.png';
import logoNav from '@/assets/logo2.png';
export default {
  name: 'MyHeader',
  props: ['step', "typePlan", "stepQuestions"],
  data() {
    return {
      displayOptions: false,
      smallScreen: false,
      logo,
      logoNav,
      MenuNav,
      isMobile: false,
      path: '',
      screen: window.screen.width,
    };
  },
  methods: {
    sitio() {
      window.location.href = "https://www.sbseguros.cl/"
    },
    returnQuestions() {
      if (this.stepQuestions == 4) {
        this.$router.push({ path: '/planes' })
      } else {
        localStorage.setItem('stepQuestions', this.stepQuestions - 1);
        localStorage.setItem('reload', true);
        window.location.reload();
      }

    },
    setPathReturn() {
      if (this.step == 0) {
        this.path = {
          name: ""
        }
      } else if (this.step == 1) {
        this.path = {
          path: "/"
        }
      } else if (this.step == 2) {
        this.path = {
          path: "/planes"
        }
      } else if (this.step == 3) {
        if (this.stepQuestions == 0) {
          if (this.typePlan == 'Individual') {
            this.path = { path: '/planes' }
          } else {
            this.path = { path: '/beneficiarios' }
          }
        }
      } else if (this.step == 4) {
        this.path = {
          path: "/dps"
        }
      } else if (this.step == 5) {
        if (this.typePlan == 'Individual') {
          this.path = { path: '/dps' }
        } else {
          this.path = { path: '/datos-beneficiarios' }
        }

      } else if (this.step == 6) {
        this.path = {
          path: "/datos-contratante"
        }
      } else if (this.step == 7) {
        this.path = {
          path: "/"
        }
      }
    }
  },
  mounted() {
    this.setPathReturn();

  },
};
</script>
<style lang="scss">
.desktop-header {
  height: 65px;
  background: #FFFFFF;
  border: 1.6px solid rgba(0, 44, 119, 1);
  box-shadow: 0px 24px 30px rgba(0, 0, 0, 0.03);
  border-radius: 15px;

  .desktop-logo {
    width: 270px;
    height: 60px;
  }

  .desktop-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

/* Estilos para mobile */
.mobile-header {
  .mobile-logo {
    width: 143px;
    height: 35px;
    display: flex;
    flex-direction: column;
    right: 0;
    justify-content: space-around;
  }
}

.mobile-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyle {
  width: 20px;
  height: 20px;
  color: #002c77;
  font-size: 40px;
  padding-top: 29.5px;
}

.textoH3 {
  position: absolute;
  width: 127px;
  height: 22px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  padding-top: 23px;
  /* primary */
  color: rgba(0, 44, 119, 1);
}
</style>
